/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React, { type FC } from "react";
import { useLocation } from "@reach/router";
import { Link, graphql, useStaticQuery } from "gatsby";
import Partners from "@/components/Home/Partners";
import { useViewport } from "@/hooks/useViewport";
import { type Service, type VideoProps, type ServiceCategory } from "@/types";

const SingleGallery = ({ params }: any) => {
  const { slug } = params;
  const { isMobile } = useViewport();

  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            id
            services {
              name
              slug
              details {
                videos {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  const service = data.allServicesJson.edges
    .map(({ node }: ServiceCategory) =>
      node.services.find((service: Service) => service.slug === slug),
    )
    .find((service: Service) => service !== undefined);

  return (
    <Layout>
      <SEO title="Videos" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Videos" pathName="Videos" />
      <div className="container mt--40">
        <div
          className={`d-flex justify-content-between align-items-center ${
            isMobile ? "flex-column" : ""
          }`}
        >
          <h2 className="label-g-p">
            {slug === "airfi-videos"
              ? "AirFi"
              : slug === "mx14-videos"
              ? "Blend14 Aero"
              : "SATTO"}
          </h2>
          <Link className="btn-back-d" to="/media">
            Back to Media
          </Link>
        </div>
        <div className="container mt--30">
          <div className="video-wrapper">
            {service?.details[0].videos?.map(({ id }: VideoProps) => (
              <YoutubeEmbed key={id} embedId={id} />
            ))}
          </div>
        </div>
        <Partners />
      </div>
    </Layout>
  );
};

const YoutubeEmbed: FC<{ embedId: string }> = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default SingleGallery;
